const TigerSugarSliderData = [
  {
    id: 1,
    name: 'TigerSugarBalmPic1'
  },
  {
    id: 2,
    name: 'TigerSugarBalmPic2'
  },
  {
    id: 3,
    name: 'TigerSugarBalmPic3'
  },
  {
    id: 4,
    name: 'TigerSugarBalmPic4'
  },
  {
    id: 5,
    name: 'TigerSugarBalmPic5'
  }
]

export default TigerSugarSliderData
