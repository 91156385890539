const MoodboardSliderData = [
  {
    id: 1,
    name: 'MoodboardPic1'
  },
  {
    id: 2,
    name: 'MoodboardPic2'
  },
  {
    id: 3,
    name: 'MoodboardPic3'
  },
  {
    id: 4,
    name: 'MoodboardPic4'
  },
  {
    id: 5,
    name: 'MoodboardPic5'
  },
  {
    id: 6,
    name: 'MoodboardPic6'
  }
]
export default MoodboardSliderData
