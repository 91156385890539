const PortfolioSliderData = [
  {
    id: 1,
    name: 'PortfolioPic1'
  },
  {
    id: 2,
    name: 'PortfolioPic2'
  }
]
export default PortfolioSliderData
