const VitamonSliderData = [
  {
    id: 1,
    name: 'VitamonPic1'
  },
  {
    id: 2,
    name: 'VitamonPic2'
  },
  {
    id: 3,
    name: 'VitamonPic3'
  },
  {
    id: 4,
    name: 'VitamonPic4'
  }
]
export default VitamonSliderData
